//Lodash Utils
import queryString from 'query-string';
import debounce from 'lodash/debounce';
import { HEIGHT_UNIT, OTHER_MEASUREMENTS_UNIT, WEIGHT_UNIT } from './constants';
//Lib Utils
const isIpad = (/Macintosh/i.test(navigator.userAgent) && navigator.maxTouchPoints && navigator.maxTouchPoints > 1) || navigator.userAgent.toLowerCase().indexOf('ipad') !== -1;
const isTouchFriendly =
  navigator.userAgent.toLowerCase().indexOf('android') !== -1 ||
  navigator.userAgent.toLowerCase().indexOf('iphone') !== -1 ||
  navigator.userAgent.toLowerCase().indexOf('react-native') !== -1;

const isIphone = navigator.userAgent.toLowerCase().indexOf('iphone') !== -1;
const isReactNative = navigator.userAgent.toLowerCase().indexOf('react-native') !== -1;

const normalizeFonts = debounce(
  () => {
    let normalizationFactor = 1;
    if (!isTouchFriendly) {
      normalizationFactor = window.innerWidth / 1680;
    } else {
      normalizationFactor = window.innerWidth / 411;
    }
    let normalizedBaseSize = Math.round(16 * normalizationFactor);
    normalizedBaseSize = normalizedBaseSize >= 14 ? normalizedBaseSize : 14;
    normalizedBaseSize = normalizedBaseSize <= 16 ? normalizedBaseSize : 16;
    document.body.style.fontSize = `${normalizedBaseSize}px`;
  },
  500,
  { leading: false, trailing: true }
);


const getURLParams = () => {
  const searchParams = window.location.search;
  return queryString.parse(searchParams);
};

const stringifyData = parsedUrlData => {
  return queryString.stringify(parsedUrlData);
};

const fetchEncryptedURLParams = () => {
  try {
    const searchParams = window.location.search;
    return searchParams.split('?params=')[1].split('&')[0];
  } catch (error) {
    console.error('Error in fetching encrypted URL Params ::', error);
    return {};
  }
};


const cookieDigester = cookie => {
  const cookieParams = cookie.split('%26%26%26%26');
  const userId = cookieParams[0];
  const accessToken = cookieParams[1];
  const refreshToken = cookieParams[2];
  const profileId = cookieParams[3];
  return { accessToken, refreshToken, userId, profileId };
};

const getCookies = () => {
  const cookies = {};
  if (document.cookie) {
    document.cookie.split(';').forEach(function (cookie) {
      var parts = cookie.split('=');
      cookies[parts.shift().trim()] = decodeURI(parts.join('='));
    });
  }
  return cookies;
};

const getOSName = () => {
  try {
    if (!navigator) {
      return null;
    } else return navigator.appVersion;
  } catch (err) {
    console.error('Error in getOSName ::', err);
  }
};

const isIos = () => {
  try {
    const userAgent = window.navigator.userAgent.toLowerCase();
    return /iphone|ipad|ipod/.test(userAgent);
  } catch (error) {
    console.error('Error in isIOS ::', error);
    return false;
  }
};

const isSafari = () => {
  let userAgentString = navigator.userAgent;
  let chromeAgent = userAgentString.indexOf('Chrome') > -1;
  let safariAgent = userAgentString.indexOf('Safari') > -1;

  // Discard Safari since it also matches Chrome
  if (chromeAgent && safariAgent) safariAgent = false;
  return safariAgent;
};

// Detects if device is in standalone mode
const isInStandaloneMode = () => 'standalone' in window.navigator && window.navigator.standalone;

const getFileExtension = file => {
  let fileExt = '';
  let fileName = '';
  try {
    let temp = file.split('/');
    if (temp.length > 0) {
      fileName = temp[temp.length - 1];
    }
    if (fileName && fileName.length > 0) {
      temp = fileName.split('.');
      if (temp.length > 1) {
        fileExt = temp[temp.length - 1];
      }
    }
    if (fileExt && fileExt.length > 0) {
      fileExt = `.${fileExt}`;
    }
    return fileExt;
  } catch (e) {
    console.error('getFileExtension err ', e);
    return '';
  }
};

const postNativeMessage = event => {
  if (isReactNative) {
    window.ReactNativeWebView.postMessage(JSON.stringify(event));
  }
};
const encodeQueryData = data => {
  const ret = [];
  for (let d in data) {
    let query = encodeURIComponent(d) + '=' + data[d];
    ret.push(query);
  }
  return ret.join('&');
};

const roundOffNumber = value => {
  return Math.round(value);
};

const toTitleCase = (word = '') => {
  return word ? word.replace(word[0], word[0].toUpperCase()) : '';
};

const clamp = (num, min, max) => Math.min(Math.max(num, min), max);

const getKeyByValue = (object, value) => {
  return Object.keys(object).find(key => object[key] === value);
}
const hardLimitMax = (limiter, value) => Math.min(Math.max(limiter, value), value);

const hardLimitMin = (limiter, value) => Math.max(Math.min(limiter, value), value);
const getUnitMultiplicationFactor = unit => {
  let multiplicationFactor = 1;
  switch (unit) {
    case OTHER_MEASUREMENTS_UNIT.CM:
      multiplicationFactor = 1 / 2.54; // cm -> inch
      break;
    case HEIGHT_UNIT.CM:
      multiplicationFactor = 1 / 2.54; //cm -> inch
      break;
    case OTHER_MEASUREMENTS_UNIT.IN:
      multiplicationFactor = 2.54; //inch -> cm
      break;
    case HEIGHT_UNIT.IN:
      multiplicationFactor = 2.54; //inch -> cm
      break;
    case WEIGHT_UNIT.KG:
      multiplicationFactor = 2.205; //kg -> lb
      break;
    case WEIGHT_UNIT.LB:
      multiplicationFactor = 1 / 2.205; //lb -> kg
      break;
    default:
      break;
  }
  return multiplicationFactor;
};

const getPaletteColor = (color) => {
  const rootStyles = getComputedStyle(document.body);
  let palette = {};
  [
    "primaryColor",
    "tranparentDarkBg",
    "floatingBG",
    "backgroundColorSecondary",
    "secondaryColor",
    "secondaryBG",
    "backgroundColor",
    "selectBG",
    "border1",
    "textColor",
    "textPrimary",
    "textSecondary",
    "inputBorderRadius",
    "buttonBorderRadius",
    "buttonTextPrimary",
    "buttonTextSecondary",
    "buttonBorderSecondary",
    "buttonBorderPrimary",
  ].forEach((prop) => {
    palette[prop] = rootStyles.getPropertyValue(`--${prop}`).trim();
  });
  return palette;
};

export {
  isTouchFriendly,
  isIphone,
  isIpad,
  isReactNative,
  normalizeFonts,
  getURLParams,
  fetchEncryptedURLParams,
  getCookies,
  cookieDigester,
  getOSName,
  isIos,
  isInStandaloneMode,
  isSafari,
  getFileExtension,
  postNativeMessage,
  encodeQueryData,
  roundOffNumber,
  stringifyData,
  toTitleCase,
  clamp,
  getKeyByValue,
  hardLimitMax,
  hardLimitMin,
  getUnitMultiplicationFactor,
  getPaletteColor,
};
