import React, { Suspense, lazy } from "react";
import { Router, Switch, Route } from "react-router-dom";
import { createBrowserHistory } from "history";
import { AuthProvider } from "contexts/AuthContext";
// import Personalise from "containers/personalise";
// import DummyProduct, { DummyProductCopy } from "containers/dummyProduct";
// import AdidasProducts from "containers/adidasProducts";
// import UploadAssets from "containers/uploadAssets";
// import {
//   ReactCanvasSceneComponentCopy
// } from "containers/babylon";
// import AvatarForm from "containers/avatarForm";
// import Login from "containers/login";
// import SelfieModule1 from "containers/selfieModule/SelfieModule1";
// import SelfieModule from "containers/selfieModule";
// import OrientationInfo from "containers/deviceOrientation"
// import SandBox from "containers/sandbox";
// import AssetViewer from "containers/assetViewer";
// import Products from "containers/Products";
// import AppModule from "containers/AppModule";
const Personalise = lazy(() => import('containers/personalise'));
const AdidasProducts = lazy(() => import('containers/adidasProducts'));
const AppModule = lazy(() => import("containers/appModule"));
const AssetViewer = lazy(() => import("containers/assetViewer"));
const SandBox = lazy(() => import("containers/sandbox"));
const OrientationInfo = lazy(() => import("containers/deviceOrientation"));
const SelfieModule = lazy(() => import("containers/selfieModule"));
const UploadAssets = lazy(() => import("containers/uploadAssets"));
const Login = lazy(() => import("containers/login"));
const SelfieModule1 = lazy(() => import("containers/selfieModule/SelfieModule1"));
const AvatarForm = lazy(() => import("containers/avatarForm"));
const KioskDemo = lazy(() => import("containers/kioskDemo/KioskDemo"));
// eslint-disable-next-line react/display-name
const Component = RouteComponent => props => (
  <RouteComponent {...props} />
);
const ComponentWithAuth = (RouteComponent, anonymousLogin, localRoute = true) => props => {
  return <AuthProvider anonymousLogin={anonymousLogin} localRoute={localRoute}>
    <RouteComponent {...props} />
  </AuthProvider>
}

/**
  * This component's sole purpose is routes management
  * Components being called using "loginHOC" are authenticated components.
    They'll prompt login screen first if the user is not logged in.
  * Components being rpassed directly (without loginHOC) are public pages.
    Login will not be prompted. If in case some part/flow of the component requires
    user-authentication, It can use "LoginWrapper" or "loginHOC" internally.
*/

const history = createBrowserHistory();
const Routes = () => {
  return (
    <Router history={history}>
      <Suspense fallback={<div></div>}>
        <Switch>
          <Route
            exact
            path="/demo1"
            component={ComponentWithAuth(() => (
              <AdidasProducts showAll={true} />
            ))}
          />
          <Route
            exact
            path="/adidas"
            component={ComponentWithAuth(() => (
              <AdidasProducts />
            ))}
          />
          {/* <Route
          exact
          path="/abfrl"
          component={ComponentWithAuth(() => (
            <AdidasProducts />
          ))}
        />
        <Route
          exact
          path="/abfrlm"
          component={ComponentWithAuth(() => (
            <AdidasProducts />
          ))}
        />
        <Route
          exact
          path="/abfrlf"
          component={ComponentWithAuth(() => (
            <AdidasProducts />
          ))}
        /> */}
        <Route
          exact
          path="/dopplr-avatar/:domain"
          component={ComponentWithAuth(() => (
            <AppModule anonymousLogin={true} />
          ), true, false)}
        />
        <Route
          exact
          path="/plugin/:domain"
          component={ComponentWithAuth(() => (
            <KioskDemo anonymousLogin={true} />
          ), true, true)}
        />
        <Route
          exact
          path="/dopplr-plugin/:domain"
          component={ComponentWithAuth(() => (
            <AppModule anonymousLogin={true} />
          ), true, true)}
        />
        {/* <Route
          exact
          path="/dopplr-avatar-old"
          component={ComponentWithAuth(() => (
            <ReactCanvasSceneComponentCopy />
          ))}
        /> */}
          <Route
            exact
            path="/personalise"
            component={ComponentWithAuth(() => (
              <Personalise />
            ))}
          />
          <Route
            exact
            path="/avatar-form"
            component={ComponentWithAuth(() => (
              <AvatarForm />
            ))}
          />
          <Route
            exact
            path="/login"
            component={ComponentWithAuth(() => (
              <Login />
            ))}
          />
          <Route
            path="/adidas2"
            component={ComponentWithAuth(() => (
              <AdidasProducts />
            ))}
          />
          <Route
            exact
            path="/lightbox"
            component={ComponentWithAuth(() => (
              <SandBox uploadMode={true} />
            ))}
          />
          <Route
            exact
            path="/sandbox"
            component={ComponentWithAuth(() => (
              <SandBox />
            ))}
          />
          <Route
            exact
            path="/uploadAssets"
            component={Component(() => (
              <UploadAssets />
            ))}
          />
          <Route
            exact
            path="/demo/:brandId"
            component={ComponentWithAuth(() => (
              <AssetViewer />
            ))}
          />
          <Route
            exact
            path="/oasis"
            component={ComponentWithAuth(() => (
              <AdidasProducts />
            ))}
          />
          <Route
            exact
            path="/valentino"
            component={ComponentWithAuth(() => (
              <AdidasProducts />
            ))}
          />
          <Route
            exact
            path="/misschase"
            component={ComponentWithAuth(() => (
              <AdidasProducts />
            ))}
          />
          <Route
            exact
            path="/indianterrain"
            component={ComponentWithAuth(() => (
              <AdidasProducts />
            ))}
          />
          <Route
            exact
            path="/dopplr"
            component={ComponentWithAuth(() => (
              <AdidasProducts />
            ))}
          />
          <Route
            exact
            path="/lotto"
            component={ComponentWithAuth(() => (
              <AdidasProducts />
            ))}
          />
          <Route
            exact
            path="/indianterrain-live"
            component={ComponentWithAuth(() => (
              <AdidasProducts bypassLogin={true}/>
            ), true)}
          />
          <Route
            exact
            path="/rarerabbit-live"
            component={ComponentWithAuth(() => (
              <AdidasProducts bypassLogin={true}/>
            ), true)}
          />
          {/* <Route
          exact
          path="/SAN"
          component={ComponentWithAuth(() => (
            <Products />
          ))}
        /> */}
          <Route
            exact
            path="/ccclub"
            component={ComponentWithAuth(() => (
              <AdidasProducts />
            ))}
          />
          <Route
            exact
            path="/cava"
            component={ComponentWithAuth(() => (
              <AdidasProducts bypassLogin={false} />
            ), false)}
          />
          <Route
            exact
            path="/test"
            component={ComponentWithAuth(() => (
              <AdidasProducts bypassLogin={false} />
            ), false)}
          />
          <Route
            exact
            path="/beinghuman"
            component={ComponentWithAuth(() => (
              <AdidasProducts bypassLogin={false} />
            ), false)}
          />
          <Route
            exact
            path="/rarerabbit"
            component={ComponentWithAuth(() => (
              <AdidasProducts bypassLogin={false} />
            ), false)}
          />
          <Route
            exact
            path="/burgerbae"
            component={ComponentWithAuth(() => (
              <AdidasProducts bypassLogin={false} />
            ), false)}
          />
          <Route
            exact
            path="/lacoste-demo"
            component={ComponentWithAuth(() => (
              <AdidasProducts bypassLogin={true} />
            ), true)}
          />
          <Route
            exact
            path="/dopplr-demo"
            component={ComponentWithAuth(() => (
              <AdidasProducts bypassLogin={true} />
            ), false)}
          />
          <Route
            exact
            path="/superkicks"
            component={ComponentWithAuth(() => (
              <AdidasProducts bypassLogin={false} />
            ), false)}
          />
          <Route
            exact
            path="/fef"
            component={ComponentWithAuth(() => (
              <AdidasProducts bypassLogin={false} />
            ), false)}
          />
          <Route
            exact
            path="/aldeno"
            component={ComponentWithAuth(() => (
              <AdidasProducts bypassLogin={false} />
            ), false)}
          />
          <Route
            exact
            path="/iff-25"
            component={ComponentWithAuth(() => (
              <AdidasProducts bypassLogin={false} />
            ), false)}
          />
          <Route
            exact
            path="/spacelines"
            component={ComponentWithAuth(() => (
              <AdidasProducts bypassLogin={false} />
            ), false)}
          />
          <Route
            exact
            path="/fef-demo"
            component={ComponentWithAuth(() => (
              <AdidasProducts bypassLogin={false} />
            ), false)}
          />
          <Route
            exact
            path="/rarerabbit-demo"
            component={ComponentWithAuth(() => (
              <AdidasProducts bypassLogin={true} />
            ), true)}
          />
          <Route
            exact
            path="/ralphlauren"
            component={ComponentWithAuth(() => (
              <AdidasProducts bypassLogin={true} />
            ), true)}
          />
          <Route
            exact
            path="/fashor"
            component={ComponentWithAuth(() => (
              <AdidasProducts bypassLogin={false} />
            ), false)}
          />
          <Route
            exact
            path="/enamor"
            component={ComponentWithAuth(() => (
              <AdidasProducts bypassLogin={true} />
            ), true)}
          />
          <Route
            exact
            path="/BHuman"
            component={ComponentWithAuth(() => (
              <AdidasProducts bypassLogin={false} />
            ), false)}
          />
          <Route
            exact
            path="/beinghumans"
            component={ComponentWithAuth(() => (
              <AdidasProducts bypassLogin={false} />
            ), false)}
          />
          <Route
            exact
            path="/gyro"
            component={ComponentWithAuth(() => (
              <OrientationInfo bypassLogin={false} />
            ), false)}
          />
          <Route
            exact
            path="/virgio"
            component={ComponentWithAuth(() => (
              <AdidasProducts bypassLogin={false} />
            ), false)}
          />
          <Route
            exact
            path="/forevernew"
            component={ComponentWithAuth(() => (
              <AdidasProducts bypassLogin={false} />
            ), false)}
          />
          <Route
            exact
            path="/iff"
            component={ComponentWithAuth(() => (
              <AdidasProducts bypassLogin={false} />
            ), false)}
          />
          <Route
            exact
            path="/nicobar"
            component={ComponentWithAuth(() => (
              <AdidasProducts />
            ))}
          />
          <Route
            exact
            path="/selfie"
            component={ComponentWithAuth(() => (
              <SelfieModule bypassLogin={false} />
            ), false)}
          />
          <Route
            exact
            path="/selfie1"
            component={Component(() => (
              <SelfieModule1 bypassLogin={false} />
            ))}
          />
          <Route
            exact
            path="/nicobar-test"
            component={ComponentWithAuth(() => (
              <AdidasProducts />
            ))}
          />
          <Route
            exact
            path="/puma"
            component={ComponentWithAuth(() => (
              <AdidasProducts bypassLogin={false} />
            ), false)}
          />
          <Route
            exact
            path="/puma-female"
            component={ComponentWithAuth(() => (
              <AdidasProducts />
            ))}
          />
          <Route
            exact
            path="/enamor"
            component={ComponentWithAuth(() => (
              <AdidasProducts bypassLogin={false} />
            ), false)}
          />
          <Route
            exact
            path="/selfie/:token/:jobId"
            component={Component(() => (
              <SelfieModule />
            ))}
          />
        </Switch>
      </Suspense>
    </Router >
  );
};

export default Routes;
